interface ClusterRegion {
  label: string;
  value: string;
}

/**
 * These regions are fairly unchanging so we hardcode them.
 */
export const RECLUSTER_ANTIBODY_ANNOTATOR_REGIONS = [
  { label: 'Heavy FR1', value: 'Heavy FR1' },
  { label: 'Heavy CDR1', value: 'Heavy CDR1' },
  { label: 'Heavy FR2', value: 'Heavy FR2' },
  { label: 'Heavy CDR2', value: 'Heavy CDR2' },
  { label: 'Heavy FR3', value: 'Heavy FR3' },
  { label: 'Heavy CDR3', value: 'Heavy CDR3' },
  { label: 'Heavy FR4', value: 'Heavy FR4' },
  { label: 'VDJ Region', value: 'VDJ Region' },

  { label: 'Light FR1', value: 'Light FR1' },
  { label: 'Light CDR1', value: 'Light CDR1' },
  { label: 'Light FR2', value: 'Light FR2' },
  { label: 'Light CDR2', value: 'Light CDR2' },
  { label: 'Light FR3', value: 'Light FR3' },
  { label: 'Light CDR3', value: 'Light CDR3' },
  { label: 'Light FR4', value: 'Light FR4' },

  { label: 'VJ Region', value: 'VJ Region' },
  { label: 'VJC Region', value: 'VJC Region' },
  { label: 'VDJC Region', value: 'VDJC Region' },
  { label: 'Heavy Constant Region', value: 'Heavy Constant Region' },
  { label: 'Light Constant Region', value: 'Light Constant Region' },
  { label: 'scFv Region', value: 'scFv Region' },
  { label: 'scFv Linker', value: 'scFv Linker' },
  { label: 'scFv Linker Match', value: 'scFv Linker Match' },
];

/**
 * These regions are considered long and computationally expensive to calculate for the Comparisons pipeline.
 */
export const LONG_ANTIBODY_ANNOTATOR_REGIONS: ClusterRegion[] = [
  { label: 'VDJ Region', value: 'VDJ Region' },
  { label: 'VDJC Region', value: 'VDJC Region' },
  { label: 'Heavy Constant Region', value: 'Heavy Constant Region' },
  { label: 'VJ Region', value: 'VJ Region' },
  { label: 'VJC Region', value: 'VJC Region' },
  { label: 'Light Constant Region', value: 'Light Constant Region' },
  { label: 'scFv Region', value: 'scFv Region' },
  { label: 'scFv Linker', value: 'scFv Linker' },
  { label: 'scFv Linker Match', value: 'scFv Linker Match' },
];

export const heavyRegions = [
  'Heavy FR1',
  'Heavy CDR1',
  'Heavy FR2',
  'Heavy CDR2',
  'Heavy FR3',
  'Heavy CDR3',
  'Heavy FR4',
  'VDJ Region',
] as const;
export const heavyGenes = ['Heavy V Gene', 'Heavy D Gene', 'Heavy J Gene', 'Heavy C Gene'] as const;
export const nameBasedLinkers = ['scFv Linker Match', 'Multi-Chain Linker Match'] as const;
export const lightRegions = [
  'Light FR1',
  'Light CDR1',
  'Light FR2',
  'Light CDR2',
  'Light FR3',
  'Light CDR3',
  'Light FR4',
  'VJ Region',
] as const;
export const lightGenes = ['Light V Gene', 'Light J Gene', 'Light C Gene'] as const;
export const scFvRegions = ['scFv Region', 'scFv Linker', 'scFv Linker Match'] as const;
export const multiChainRegions = [
  'Multi-Chain Linker',
  'Multi-Chain Region',
  'Multi-Chain Linker Match',
] as const;
export const chainType = ['Heavy', 'Light'] as const;
export type allHeavy = (typeof heavyRegions)[number] | (typeof heavyGenes)[number];
export type allLight = (typeof lightRegions)[number] | (typeof lightGenes)[number];
export type PrefixableRegions = allHeavy | allLight;
export type ChainType = (typeof chainType)[number];
export type ChainPrefix = `${ChainType}-${number}`;
export type UnprefixedRegions =
  | PrefixableRegions
  | (typeof scFvRegions)[number]
  | (typeof multiChainRegions)[number];

export interface PrefixedRegion {
  chain: ChainPrefix;
  name: PrefixableRegions;
}

export type ParsedRegion = { chain: null; name: UnprefixedRegions } | PrefixedRegion;
export type GenericRegion = 'Full Sequence' | 'Template Region' | 'Template Matches';
export type Regions =
  | UnprefixedRegions
  | `${PrefixedRegion['chain']}: ${PrefixedRegion['name']}`
  | GenericRegion;

export function toRegionString(region: ParsedRegion): string {
  if (region.chain) {
    return `${region.chain}: ${region.name}`;
  }
  return region.name;
}

export function fromRegionString(region: UnprefixedRegions): ParsedRegion {
  return { chain: null, name: region };
}

export function parseRegion(regionString: string): ParsedRegion {
  const splitRegion = regionString.split(': ');
  if (splitRegion.length === 1) {
    return fromRegionString(splitRegion[0] as UnprefixedRegions);
  }
  return {
    chain: splitRegion[0] as ChainPrefix,
    name: splitRegion[1] as UnprefixedRegions,
  } as ParsedRegion;
}

import { createSelector } from '@ngrx/store';
import { AppState } from '../core.store';
import {
  OrganizationSetting,
  OrganizationSettingKind,
  OrganizationSettingsState,
  SettingState,
} from '../models/settings/setting.model';
import { LumaConfig } from '../luma/luma-config/luma-config.model';
import { SelectOption } from '../models/ui/select-option.model';
import { sortByString } from 'src/app/shared/sort.util';

export const selectOrganizationSettings = (state: AppState) => state.organizationSettings;
export const selectAllOrganizationSettingsByKind = (kind: OrganizationSettingKind) =>
  createSelector(selectOrganizationSettings, (state: OrganizationSettingsState) => {
    const ids = Object.keys(state.settings).filter((id) => state.settings[id].kind === kind);
    const settings = ids.reduce((obj, id) => ({ ...obj, [id]: state.settings[id] }), {});
    const settingStates = ids.reduce((obj, id) => ({ ...obj, [id]: state.settingStates[id] }), {});
    return {
      settings,
      settingStates,
    };
  });

export const selectAllOrganizationSettingsByKindWithState = (
  kind: OrganizationSettingKind,
  settingsStates: SettingState[],
) =>
  createSelector(selectAllOrganizationSettingsByKind(kind), (state: OrganizationSettingsState) => {
    const ids = Object.keys(state.settingStates);
    const settings: Record<string, OrganizationSetting> = {};
    ids.forEach((id) => {
      if (settingsStates.some((set) => state.settingStates[id] === set)) {
        settings[id] = state.settings[id];
      }
    });
    return settings;
  });

export const selectPendingOrganizationSettingsObjectByKind = (kind: OrganizationSettingKind) =>
  selectAllOrganizationSettingsByKindWithState(kind, ['DELETING', 'SAVING']);
export const selectKindObject = (kind: OrganizationSettingKind) =>
  selectAllOrganizationSettingsByKindWithState(kind, ['SAVED']);

export const selectOrganizationSettingsByKind = (kind: OrganizationSettingKind) =>
  createSelector(selectKindObject(kind), (state: Record<string, OrganizationSetting>) =>
    Object.values(state),
  );
export const selectPendingOrganizationSettingsByKind = (kind: OrganizationSettingKind) =>
  createSelector(
    selectPendingOrganizationSettingsObjectByKind(kind),
    (state: Record<string, OrganizationSetting>) => Object.values(state),
  );

export const selectBioregisterSettings = selectOrganizationSettingsByKind('bioregister');
export const selectBioregisterUrl = createSelector(
  selectBioregisterSettings,
  (settings: OrganizationSetting[]) =>
    settings.find((setting) => setting.name === 'bioregisterURL').data,
);
export const selectLumaSettings = selectOrganizationSettingsByKind('luma');
export const selectLumaSettingsByName = createSelector(
  selectLumaSettings,
  (lumaSettings) =>
    Object.fromEntries(lumaSettings.map((setting) => [setting.name, setting])) as Partial<{
      [K in keyof LumaConfig]: OrganizationSetting<string>;
    }>,
);
export const selectLumaConfig = createSelector(
  selectLumaSettings,
  (lumaSettings) =>
    Object.fromEntries(
      lumaSettings.map((setting) => [setting.name, setting.data]),
    ) as Partial<LumaConfig>,
);

export const selectLabels = selectOrganizationSettingsByKind('label');
export const selectPendingLabels = selectPendingOrganizationSettingsByKind('label');
export const selectNameSchemes = selectOrganizationSettingsByKind('file-naming-scheme');
export const selectPendingNameSchemes =
  selectPendingOrganizationSettingsByKind('file-naming-scheme');
export const selectScaffolds = selectOrganizationSettingsByKind('scaffoldv2');
export const selectPendingScaffolds = selectPendingOrganizationSettingsByKind('scaffoldv2');

const selectOrganizationSettingByID = (id: string, kind: OrganizationSettingKind) =>
  createSelector(selectKindObject(kind), (state: Record<string, OrganizationSetting>) => state[id]);

export const selectLabelByID = (id: string) => selectOrganizationSettingByID(id, 'label');

/** Selects the label with matching name and color. Emits undefined if no such label exists. */
export const selectLabelByNameAndColor = (name: string, color: string) =>
  createSelector(selectLabels, (labels) =>
    labels.find((label) => label.name === name && label.data.color === color),
  );
export const selectNameSchemeByID = (id: string) =>
  selectOrganizationSettingByID(id, 'file-naming-scheme');
export const selectScaffold = (id: string) => selectOrganizationSettingByID(id, 'scaffoldv2');
export const selectNameSchemesByClassifications = (classifications?: string[]) =>
  createSelector(selectNameSchemes, (nameSchemes) => {
    if (!classifications) {
      return nameSchemes;
    }
    return nameSchemes.filter((nameScheme) =>
      classifications.every((classification) =>
        nameScheme.data.fields.some(
          (field: any) => field.classification && field.classification === classification,
        ),
      ),
    );
  });

/**
 * Converts settings to SelectOptions, with the displayName set to the setting
 * name and the option value set to the setting ID. Options are sorted by name
 * in ascending order.
 *
 * @param settings the settings to convert
 * @returns select options for the settings
 */
export function orgSettingsToSelectOptions(settings: OrganizationSetting[]): SelectOption[] {
  return settings
    .map((setting) => new SelectOption(setting.name, setting.id))
    .sort(sortByString((option) => option.displayName));
}

import {
  parseRegion,
  RECLUSTER_ANTIBODY_ANNOTATOR_REGIONS,
} from '../core/antibodyAnnotatorRegions.service';

export function naturalSortCompare(a: string, b: string): number {
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
}

export function naturalSortOnStringProperty(property: string): (a: any, b: any) => number {
  return (a, b) => {
    const aProperty = (typeof a !== 'undefined' ? a[property] : '').toLowerCase();
    const bProperty = (typeof b !== 'undefined' ? b[property] : '').toLowerCase();
    return naturalSortCompare(aProperty, bProperty);
  };
}

/**
 * Allows objects to be sorted by a string representation.
 *
 * @param stringify a function that returns a string representation of an object to sort
 * @returns a function that can be passed to Array.sort
 */
export function sortByString<T>(stringify: (obj: T) => string) {
  return (a: T, b: T): number => stringify(a).localeCompare(stringify(b));
}

export function sortGeneCombinationName(geneA: string, geneB: string): number {
  const sortingOrder = [
    'Heavy VJ Gene',
    'Heavy-Light V Gene',
    'Heavy-Light J Gene',
    'Light VJ Gene',
  ];
  const aParsed = parseRegion(geneA);
  const bParsed = parseRegion(geneB);
  if (aParsed.chain !== bParsed.chain) {
    return (aParsed.chain ?? '').localeCompare(bParsed.chain ?? '', undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }
  const aIndex = sortingOrder.findIndex((gene) => gene === aParsed.name.trim());
  const bIndex = sortingOrder.findIndex((gene) => gene === bParsed.name.trim());

  // Unknown gene should be sorted later
  if (aIndex === -1) {
    return 1;
  }
  if (bIndex === -1) {
    return -1;
  }

  return aIndex - bIndex;
}

export function sortAntibodyRegionByName(regionA: string, regionB: string): number {
  const sortingOrder = [
    { label: 'All Sequences' },
    { label: 'Sequences' },
    { label: 'Identified as Heavy Chain' },
    { label: 'Identified as Light Chain' },
    { label: 'Identified as Heavy/Light Pair' },
    { label: 'Pairs Merged' },
    { label: 'Pairs Not Merged' },
    { label: 'Unpaired' },
    ...RECLUSTER_ANTIBODY_ANNOTATOR_REGIONS,
    { label: 'Heavy V Gene' },
    { label: 'Heavy D Gene' },
    { label: 'Heavy J Gene' },
    { label: 'Heavy C Gene' },
    { label: 'Light V Gene' },
    { label: 'Light J Gene' },
    { label: 'Light C Gene' },
    { label: 'Multi-Chain Linker' },
    { label: 'Multi-Chain Region' },
  ];

  const regionAWithChain = parseRegion(regionA);
  const regionBWithChain = parseRegion(regionB);
  if (regionAWithChain.chain !== regionBWithChain.chain) {
    return (regionAWithChain.chain ?? '').localeCompare(regionBWithChain.chain ?? '', undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  }

  const aIndex = sortingOrder.findIndex((region) => region.label === regionAWithChain.name.trim());
  const bIndex = sortingOrder.findIndex((region) => region.label === regionBWithChain.name.trim());

  // Unknown region should be sorted later
  if (aIndex === -1) {
    return 1;
  }
  if (bIndex === -1) {
    return -1;
  }

  return aIndex - bIndex;
}

/**
 * Applies a mapping function to the object's values, returning a new object
 * with the same keys and transformed values.
 *
 * @param obj the object
 * @param mapFn the function to apply to the object's values
 */
export function mapObjectValues<K extends string | number | symbol, T, U>(
  obj: Record<K, T>,
  mapFn: (value: T) => U,
): Record<K, U> {
  const mappedEntries = Object.entries(obj).map(([i, value]) => [i, mapFn(value as T)]);
  return Object.fromEntries(mappedEntries);
}

/**
 * Returns a comparator function that checks whether two objects are equal by
 * mapping them to strings via the supplied stringify function. The returned
 * function is compatible with RxJS's distinctUntilChanged.
 *
 * @param stringify A function that maps the input objects to strings
 * @returns a comparator
 */
export function compareStrings<T>(stringify: (obj: T) => string): (prev: T, current: T) => boolean {
  return (prev, current) => {
    if (prev === current || prev == null || current == null) {
      return prev === current;
    }
    return stringify(prev) === stringify(current);
  };
}

/**
 * Recursively merge properties of one object into the other.
 *
 * @param obj1 object to merge.
 * @param obj2 object to merge with.
 */
export function mergeNestedObjects(obj1: any, obj2: any): any {
  const mergedObject = { ...obj1 };

  for (const key of Object.keys(obj2)) {
    if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
      // Recursively merge nested objects
      mergedObject[key] = mergeNestedObjects(obj1[key] || {}, obj2[key]);
    } else {
      // Copy values for non-nested properties
      mergedObject[key] = obj2[key];
    }
  }

  return mergedObject;
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { elapsedDays } from '../../../shared/utils/date';
import { OrganizationProfileService } from '../../organisation/organization-profile.service';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FolderService } from '../../folders/folder.service';
import { FolderUtils } from '../../folders/folder.utils';
import { Folder } from '../../folders/models/folder.model';
import { ItemV2 } from '../../../../nucleus/v2/models/item.v2.model';
import { CleanUp } from '../../../shared/cleanup';
import { DocumentExpiryBadgeComponent } from '../../../shared/document-expiry-badge/document-expiry-badge.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-file-name-renderer',
  templateUrl: './file-name-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DocumentExpiryBadgeComponent, AsyncPipe],
})
export class FileNameRendererComponent extends CleanUp implements ICellRendererAngularComp {
  name: string;
  remainingDays$: Observable<number>;
  expirable$: Observable<boolean>;
  constructor(
    private orgProfileService: OrganizationProfileService,
    private folderService: FolderService,
  ) {
    super();
  }

  agInit(cell: ICellRendererParams) {
    this.name = cell.value;

    const document = cell.data as ItemV2;
    const expiryDays$ = this.orgProfileService
      .getCurrentOrgProfileDocumentExpiryDays()
      .pipe(takeUntil(this.ngUnsubscribe));
    this.expirable$ = expiryDays$.pipe(
      withLatestFrom(this.folderService.get(document.parentID)),
      map(([expiryDays, parentFolder]) => {
        const documentCanExpire = document.canExpire ?? true;
        return FolderUtils.isFolder(parentFolder as Folder) && documentCanExpire && expiryDays > 0;
      }),
    );

    this.remainingDays$ = expiryDays$.pipe(
      map((expiryDays) => {
        const createTime = new Date(document.createdAt).getTime();
        return Math.ceil(expiryDays - elapsedDays(createTime));
      }),
    );
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IS_PREMIUM_ORG } from '../access-check/access-check-condition.model';
import { HideIfDirective } from '../access-check/directives/hide/hide-if.directive';
import { NgClass } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bx-document-expiry-badge',
  templateUrl: './document-expiry-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HideIfDirective, NgClass, MatIconModule],
})
export class DocumentExpiryBadgeComponent {
  @Input() expirable: boolean;
  @Input() remainingDays: number;
  @Input() showAsBadge = false;
  readonly isPremiumOrg = IS_PREMIUM_ORG;

  get expiringSoon() {
    return isNaN(this.remainingDays) || this.remainingDays < 1;
  }
}

import { Feature } from '../../../app/features/feature-switch/features';
import { OrganizationProfileCategory } from '../../../nucleus/v2/models/organization-profile.v2.model';

/**
 * Used to conditionally show/hide/disable elements based on the logged-in user's
 * permissions & attributes. You can specify just a Feature as a string as
 * shorthand, or specify multiple properties with the object format.
 */
export type AccessCondition =
  | Feature
  | {
      /** Evaluates to true if the user has this feature enabled */
      feature?: Feature;
      /** Evaluates to true if the user's organization belongs to one of these profiles */
      orgProfiles?: OrganizationProfileCategory[];
    };

/**
 * AccessConditon that evaluates to true for users that belong to a free tier
 * organization.
 */
export const IS_FREE_ORG: AccessCondition = { orgProfiles: ['free'] };

/**
 * AccessCondtion that evaluates to true for users that belong to an associate
 * organization.
 */
export const IS_ASSOCIATE_ORG: AccessCondition = { orgProfiles: ['associate'] };

/**
 * AccessCondtion that evaluates to true for users that belong to an non-premium
 * organization.
 */
export const IS_UNPAID_ORG: AccessCondition = { orgProfiles: ['associate', 'free'] };

/**
 * AccessCondtion that evaluates to true for users that belong to a non-free-tier
 * organization.
 */
export const IS_PREMIUM_ORG: AccessCondition = { orgProfiles: ['standard', 'unrestricted'] };

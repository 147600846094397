import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FeatureSwitchService } from '../../features/feature-switch/feature-switch.service';
import { AccessCondition } from './access-check-condition.model';
import { OrgProfileCheckService } from './org-profile-check/org-profile-check.service';

/**
 * Service that checks whether the logged-in user meets AccessConditions.
 */
@Injectable({
  providedIn: 'root',
})
export class AccessCheckService {
  constructor(
    private featureSwitchService: FeatureSwitchService,
    private orgProfileCheckService: OrgProfileCheckService,
  ) {}

  /**
   * If the current user meets the access conditions, it will emit true,
   * otherwise false. Returns a continuous Observable that emits a new value
   * whenever the organization profile or auth state changes in a way that
   * affects user access.
   *
   * @param condition The access condition to check
   * @returns Observable of boolean values
   */
  hasAccess(condition: AccessCondition): Observable<boolean> {
    if (typeof condition === 'string') {
      return this.featureSwitchService.isEnabled(condition);
    }
    if (!condition.feature && !condition.orgProfiles) {
      return of(true);
    }
    const accessChecks: Observable<boolean>[] = [];
    if (condition.feature) {
      accessChecks.push(this.featureSwitchService.isEnabled(condition.feature));
    }
    if (condition.orgProfiles) {
      accessChecks.push(
        this.orgProfileCheckService.hasOrgProfileCategory(...condition.orgProfiles),
      );
    }
    return combineLatest(accessChecks).pipe(
      map((res) => res.every((access) => access)),
      distinctUntilChanged(),
    );
  }

  /**
   * If the current user meets the access conditions, it will emit true,
   * otherwise false. Then the Observable will complete.
   *
   * @param condition The access condition to check
   * @returns Single-emission Observable containing a boolean
   */
  hasAccessOnce(condition: AccessCondition): Observable<boolean> {
    if (typeof condition === 'string') {
      return this.featureSwitchService.isEnabledOnce(condition);
    }
    if (!condition.feature && !condition.orgProfiles) {
      return of(true);
    }
    const accessChecks: Observable<boolean>[] = [];
    if (condition.feature) {
      accessChecks.push(this.featureSwitchService.isEnabledOnce(condition.feature));
    }
    if (condition.orgProfiles) {
      accessChecks.push(
        this.orgProfileCheckService.hasOrgProfileCategoryOnce(...condition.orgProfiles),
      );
    }
    return forkJoin(accessChecks).pipe(map((res) => res.every((access) => access)));
  }
}

import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { inject, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  Principal,
  PrincipalGroup,
  PrincipalUser,
} from '../../../nucleus/v1-1/models/organizations/principals/principal.v1-1';
import { NucleusDataResponse } from '../../../nucleus/nucleus.model';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { AppState } from '../core.store';
import { Store } from '@ngrx/store';
import {
  selectIsAuthenticatedAfterVerification,
  selectOrganizationID,
} from '../auth/auth.selectors';
import {
  NewOrganization,
  OrganizationDetails,
  OrganizationManagementService,
} from '@geneious/nucleus-api-client';
import { OrganizationUpdate } from '@geneious/nucleus-api-client/model/organization-update';
import { OrganizationUserInfo } from '@geneious/nucleus-api-client/model/organization-user-info';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private readonly orgURI: string;
  private readonly orgURIV2: string;
  private readonly cacheTime = 300_000;
  private nextCacheRefreshTimestamp = 0;
  private cachedOrganizationID: string;
  private principalUsers$: Observable<PrincipalUser[]>;

  private readonly organizationManagementService = inject(OrganizationManagementService);

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.orgURI = `${this.config.nucleusApiBaseUrl}/api/nucleus/v1-1/organizations`;
    this.orgURIV2 = `${this.config.nucleusApiBaseUrl}/api/nucleus/v2/organizations`;
    // Force cache refresh on logout
    this.store
      .pipe(selectIsAuthenticatedAfterVerification)
      .subscribe(
        () => (this.principalUsers$ = this.fetch<PrincipalUser>('users').pipe(shareReplay(1))),
      );
  }

  getAll(): Observable<OrganizationDetails[]> {
    return this.organizationManagementService
      .listOrganizations()
      .pipe(map((response) => response.data));
  }

  get(organizationID: string): Observable<OrganizationDetails> {
    return this.organizationManagementService
      .getOrganization(organizationID)
      .pipe(map((response) => response.data));
  }

  create(organization: NewOrganization): Observable<OrganizationDetails> {
    return this.organizationManagementService
      .createOrganization(organization)
      .pipe(map((response) => response.data));
  }

  update(id: string, organization: OrganizationUpdate): Observable<OrganizationDetails> {
    return this.organizationManagementService
      .updateOrganization(id, organization)
      .pipe(map((response) => response.data));
  }

  delete(id: string, strategy: 'deactivate' | 'softDelete' | 'hardDelete'): Observable<any> {
    return this.organizationManagementService.deleteOrganization(id, undefined, strategy);
  }

  getPrincipalGroups(): Observable<PrincipalGroup[]> {
    return this.fetch<PrincipalGroup>('groups');
  }

  /**
   * Fetches principal users for a given organization. The response data is cached for
   * {@link cacheTime} ms, because at the time of writing the Info Viewer relies heavily on this
   * endpoint for retrieving user names, and the payload can be large in an organization with many
   * users. TODO: use NGRX for caching instead
   * @param organizationID optional organizationID of the principals to query. If not passed a
   *     value current logged in user's organization will be used
   * @returns Observable of a list of PrincipalUsers
   */
  getPrincipalUsers(organizationID?: string): Observable<PrincipalUser[]> {
    const now = Date.now();
    if (now > this.nextCacheRefreshTimestamp || this.cachedOrganizationID !== organizationID) {
      this.nextCacheRefreshTimestamp = now + this.cacheTime;
      this.cachedOrganizationID = organizationID;
      this.principalUsers$ = this.fetch<PrincipalUser>('users', organizationID).pipe(
        shareReplay(1),
      );
    }
    return this.principalUsers$;
  }

  getUserInfo(organizationID?: string): Observable<OrganizationUserInfo> {
    return this.organizationManagementService
      .getOrganizationUsersInfo(organizationID)
      .pipe(map((response) => response.data));
  }

  private fetch<T extends Principal>(type: string, organizationID?: string): Observable<T[]> {
    const orgID$ = organizationID ? of(organizationID) : this.store.select(selectOrganizationID);
    return orgID$.pipe(
      first(),
      map((orgID) => `${this.orgURI}/${orgID}/principals/${type}`),
      switchMap((url) => this.http.get<NucleusDataResponse<T[]>>(url)),
      map((response) => response.data),
    );
  }
}

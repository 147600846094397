import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { OrganizationProfileService } from '../../../core/organisation/organization-profile.service';
import { OrganizationProfileCategory } from 'src/nucleus/v2/models/organization-profile.v2.model';

/**
 * Service that checks the logged-in user's organization profile.
 */
@Injectable({
  providedIn: 'root',
})
export class OrgProfileCheckService {
  private readonly orgProfile$: Observable<OrganizationProfileCategory>;

  constructor(private orgProfileService: OrganizationProfileService) {
    this.orgProfile$ = this.orgProfileService
      .getCurrentOrgProfile()
      .pipe(map((profile) => profile.category as OrganizationProfileCategory));
  }

  /**
   * If the logged-in user's organization belongs to one of the supplied org
   * profile categories, it will emit `true` (otherwise, `false`).
   * Returns a continuous Observable that emits the most recent value on
   * subscription, then a new value whenever the AuthState's organizationID
   * changes.
   *
   * @param categories Organization profile categories to check against
   * @returns Observable of boolean values
   */
  hasOrgProfileCategory(...categories: OrganizationProfileCategory[]): Observable<boolean> {
    return this.orgProfile$.pipe(
      map((category) => category !== null && categories.includes(category)),
    );
  }

  /**
   * If the logged-in user's organization belongs to one of the supplied org
   * profile categories, it will emit `true` (otherwise, `false`). Then the
   * Observable will complete.
   *
   * @param categories Organization profile categories to check against
   * @returns Single-emission Observable containing a boolean
   */
  hasOrgProfileCategoryOnce(...categories: OrganizationProfileCategory[]): Observable<boolean> {
    return this.hasOrgProfileCategory(...categories).pipe(take(1));
  }
}

import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { selectOrganizationID } from '../auth/auth.selectors';
import { AppState } from '../core.store';
import { OrganizationService } from './organization.service';
import { OrganizationProfileInfo } from '@geneious/nucleus-api-client';

@Injectable({
  providedIn: 'root',
})
export class OrganizationProfileService {
  private readonly currentOrgProfile$: Observable<OrganizationProfileInfo>;

  constructor(
    private store: Store<AppState>,
    private orgService: OrganizationService,
  ) {
    this.currentOrgProfile$ = this.store.pipe(
      select(selectOrganizationID),
      filter((organizationID) => !!organizationID),
      switchMap((organizationID) => this.orgService.get(organizationID)),
      map((organization) => organization.organizationProfile),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  getCurrentOrgProfile(): Observable<OrganizationProfileInfo> {
    return this.currentOrgProfile$;
  }

  getCurrentOrgProfileDocumentExpiryDays(): Observable<number> {
    return this.currentOrgProfile$.pipe(map((profile) => profile.documentExpiryDays));
  }
}

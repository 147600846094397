import { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { FormatterService } from '../../../shared/formatter.service';
import { FileNameRendererComponent } from '../../files-table/file-name-renderer/file-name-renderer.component';

/**
 * Utility function to duplicate code
 * @returns desired value or empty string if not found
 */
function getProperty(params: any, key: string): string {
  if (params && params.data) {
    const myData = params.data;
    return myData[key];
  } else {
    return '';
  }
}

// Rough schema for the file-table's client-grid-v2-component
// Ordered for preferred presentation.
// @ts-ignore
export const fileTableDefs: ColDef[] = [
  {
    headerName: 'Name',
    field: 'name',
    cellClass: 'name-cell',
    width: 250,
    editable: true,
    cellRenderer: FileNameRendererComponent,
  },
  {
    headerName: 'Description',
    field: 'description',
    width: 200,
  },
  {
    headerName: 'File Type',
    field: 'documentType',
    cellClass: 'fileType-cell',
    width: 100,
  },
  {
    headerName: 'Created Date',
    field: 'createdAt',
    comparator: dateComparator,
    valueFormatter: (params) =>
      params && params.data ? FormatterService.formatDateString(params.data.createdAt) : '-',
  },
  {
    headerName: 'Modified Date',
    field: 'modifiedAt',
    comparator: dateComparator,
    valueFormatter: (params) =>
      params && params.data ? FormatterService.formatDateString(params.data.modifiedAt) : '-',
  },
  {
    headerName: 'Length',
    field: 'sequence_length',
    valueFormatter: (params) =>
      FormatterService.cleanupAndShowLocalFormat(getProperty(params, 'sequence_length')),
  },
  {
    headerName: 'No. Sequences',
    field: 'number_of_sequences',
    valueFormatter: (params) =>
      FormatterService.cleanupAndShowLocalFormat(getProperty(params, 'number_of_sequences')),
  },
  { headerName: 'Molecule type', field: 'molType' },
  {
    headerName: 'Nucleotides Count',
    field: 'nucleotidesCount',
    valueFormatter: (params) =>
      FormatterService.cleanupAndShowLocalFormat(getProperty(params, 'nucleotidesCount')),
  },
  { headerName: '% Similarity', field: 'percentage_similarity' },
  {
    headerName: 'Minimum Sequence Length',
    field: 'minimumSequenceLength',
    valueFormatter: (params) =>
      FormatterService.cleanupAndShowLocalFormat(getProperty(params, 'minimumSequenceLength')),
  },
  { headerName: 'Sequence type', field: 'sequenceType' },
  { headerName: 'Residues', field: 'sequence_residues' },
  {
    headerName: 'Document Size',
    field: 'document_size',
    // TODO Use nice MB formatter.
    hide: true,
  },
  {
    headerName: 'Mean Coverage',
    field: 'meanCoverage',
    hide: true,
    valueFormatter: (params) => defaultValueFormatter(params, { round: 2 }),
  },
  {
    headerName: 'Reference Sequence Index',
    field: 'referenceSequenceIndex',
    hide: true,
    // Sometimes gives "-1".
    valueGetter: (params) => {
      const value = getValue(params);
      return value === '-1' ? '-' : value;
    },
  },
  {
    headerName: 'Free end Gaps',
    field: 'freeEndGaps',
    hide: true,
    valueFormatter: booleanValueFormatter,
  },
  { headerName: 'Topology', field: 'topology', hide: true },
  { headerName: '% Identical', field: 'percentage_identical', hide: true },
  { headerName: 'GC Percent', field: 'gcPercent', hide: true },
  { headerName: 'Reference Sequence Length', field: 'referenceSequenceLength', hide: true },
  { headerName: 'Medium quality %', field: 'mediumQualityPercent', hide: true },
  { headerName: 'Low quality %', field: 'lowQualityPercent', hide: true },
  { headerName: 'Bin fail reason', field: 'BinFailReason', hide: true },
  { headerName: 'Ambiguities', field: 'ambiguities', hide: true },
  { headerName: 'Post trim length', field: 'postTrimLength', hide: true },
  { headerName: 'Bin', field: 'bin', hide: true },
  { headerName: 'Size', field: 'size', hide: true },
];

/**
 * Returns a colDef value using the field specified by the colDef.
 */
export function getValue(params: any) {
  return params.data[params.colDef.field];
}

export function booleanValueFormatter(params: any) {
  const value = getValue(params);
  if (value === 'true') {
    return '✔';
  } else if (value === 'false') {
    return '✘';
  } else {
    return '';
  }
}

export function isColDef(col: ColDef | ColGroupDef): col is ColDef {
  return (<ColDef>col).field !== undefined || (<ColDef>col).colId !== undefined;
}

export function isColGroupDef(col: ColDef | ColGroupDef): col is ColGroupDef {
  return (<ColGroupDef>col).children !== undefined;
}

export function defaultValueFormatter(params: any, options: any = {}) {
  options.prefix = options.prefix || '';
  options.postfix = options.postfix || '';
  options.round = options.round || 0;

  if (params) {
    const value = getValue(params);
    const formatted = FormatterService.commas(value, options.round);
    return `${options.prefix}${formatted}${options.postfix}`;
  } else {
    console.log(`Failed to render field "${params.colDef.field}"`);
    return '-';
  }
}

/**
 * WARNING!
 * This function will remove the timezone from the 'ddd MMM DD HH:mm:ss z YYYY' (Geneious Prime format) date format.
 * This is done because for some ag-grid does not sort correctly when the timezones are used in the calculation.
 * This function should handle date strings in formats other than the Geneious Prime one but does not guarantee
 * that the end result of the calculation will be correct and/or dates will be correctly sorted.
 * Please use with caution and be aware of the above. We do not have a better alternative
 * for comparing dates at this time so this function is not deprecated.
 *
 * Timezones in the comparator cause sorting to be wrong so we remove them.
 * Timezones will still be visible in the row's modified date tooltip.
 * @param a - date string
 * @param b - date string
 */
export function dateComparator(a: string, b: string): number {
  // This pattern will match 'TZ*example: UTC* YYYY(*example: 2019)' and create a group for the year so it can be added back.
  const patternForRemovingTimezones = /\w*\s(\w*$)/;
  const geneiousPrimeDatePattern = /^\w{3}\s\w{3}\s\d{2}\s(\d{2}:){2}\d{2}\s\w{1,5}\s\d{4}$/;

  // $1 = the group in parentheses - \w*$. Sorting is incorrect when timezones are present in the calculation.
  const dateA = a
    ? a.match(geneiousPrimeDatePattern)
      ? a.replace(patternForRemovingTimezones, '$1')
      : a
    : a;
  const dateB = b
    ? b.match(geneiousPrimeDatePattern)
      ? b.replace(patternForRemovingTimezones, '$1')
      : b
    : b;

  const timestampA = new Date(dateA).getTime();
  const timestampB = new Date(dateB).getTime();

  // Handle cases where dates are invalid (this might result in broken sorting).
  if (isNaN(timestampA) && isNaN(timestampB)) {
    return 0;
  }

  if (isNaN(timestampA)) {
    return -1;
  }

  if (isNaN(timestampB)) {
    return 1;
  }

  return timestampA - timestampB;
}

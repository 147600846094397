export class SelectOption<T = string> {
  constructor(
    readonly displayName: string,
    readonly value: T,
  ) {}
}

export class SelectGroup<T = string> {
  label?: string;
  options: SelectOption<T>[];

  constructor(options: SelectOption<T>[], label?: string) {
    this.label = label;
    this.options = options;
  }

  isEmpty(): boolean {
    return this.options.length === 0;
  }
}

export function isSelectGroup<T>(opt: SelectOption<T> | SelectGroup<T>): opt is SelectGroup<T> {
  return 'options' in opt;
}

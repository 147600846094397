<ng-container *bxHideIf="isPremiumOrg">
  @if (expirable) {
    <span
      class="data-expiry-badge d-inline-block"
      [ngClass]="showAsBadge ? 'badge bg-brand' : 'small text-brand fw-bold'"
    >
      <span class="align-top">
        <mat-icon inline>access_time</mat-icon>
      </span>
      {{ expiringSoon ? 'Expiring Soon' : remainingDays + ' Day' + (remainingDays > 1 ? 's' : '') }}
    </span>
  }
</ng-container>
